export const isDisposableEmailApiCheck = async (email: string): Promise<boolean> => {
	try {
		const controller = new AbortController();
		const timeout = setTimeout(() => controller.abort(), 2000);

		const response = await fetch(`https://www.validator.pizza/email/${email}`, {
			signal: controller.signal
		});
		clearTimeout(timeout);

		const data = await response.json();
		return data.disposable === true;
	} catch (error) {
		console.error('Error checking disposable email:', error);
		return false; // Fail-safe, assume non-disposable if check fails
	}
};

// taken from https://github.com/disposable-email-domains/disposable-email-domains
export const disposableEmailDomains = new Set([
	'no.cc', // custom, added manually
	'garbagecollector.org',
	'exelica.com',
	'rvb.ro',
	'lzoaq.com',
	'cubiclink.com',
	'freemails.ml',
	'vkfu.ru',
	'volaj.com',
	'flu.cc',
	'mailexpire.com',
	'temporaryemail.net',
	'kalapi.org',
	'mailfree.ml',
	'mailgutter.com',
	'developermail.com',
	'trashymail.net',
	'zetmail.com',
	'boxomail.live',
	'awiki.org',
	'ramin200.site',
	'thisisnotmyrealemail.com',
	'spamspot.com',
	'anonmails.de',
	'smapfree24.de',
	'thisurl.website',
	'mymail90.com',
	'twzhhq.online',
	'kwift.net',
	'trialmail.de',
	'griuc.schule',
	'linshiyouxiang.net',
	'tevstart.com',
	'wmail.cf',
	'crossroadsmail.com',
	'ereplyzy.com',
	'it7.ovh',
	'l-c-a.us',
	'ttirv.org',
	'fuwa.li',
	'moakt.ws',
	'emailsecurer.com',
	'trashmails.com',
	'hostlaba.com',
	'oloh.ru',
	'altuswe.us',
	'mail333.com',
	'kumli.racing',
	'aschenbrandt.net',
	'insorg-mail.info',
	'freeplumpervideos.com',
	'buymoreplays.com',
	'nowhere.org',
	'kommunity.biz',
	'veryday.eu',
	'9me.site',
	'chasefreedomactivate.com',
	'get-mail.tk',
	'thecity.biz',
	'mintemail.com',
	'xn--d-bga.net',
	'indoserver.stream',
	'adventwe.us',
	'mailde.de',
	'5ghgfhfghfgh.tk',
	'badpotato.tk',
	'plexfirm.com',
	'durandinterstellar.com',
	'acucre.com',
	'drdrb.com',
	'richfinances.pw',
	'upliftnow.com',
	'makemenaughty.club',
	'uplipht.com',
	'armyspy.com',
	'awatum.de',
	'crepeau12.com',
	'tafmail.com',
	'viewcastmedia.net',
	'sinaite.net',
	'abundantwe.us',
	'spamarrest.com',
	'ushijima1129.ml',
	'spambog.net',
	'vemomail.win',
	'buccalmassage.ru',
	'iroid.com',
	'consumerriot.com',
	'midcoastsolutions.net',
	'completegolfswing.com',
	'midiharmonica.com',
	'qisdo.com',
	'rinseart.com',
	'besttempmail.com',
	'0n0ff.net',
	'confmin.com',
	'alldirectbuy.com',
	'para2019.ru',
	'raetp9.com',
	'doojazz.com',
	'insanumingeniumhomebrew.com',
	'matra.site',
	'benipaula.org',
	'despammed.com',
	'vjoid.ru',
	'kjkszpjcompany.com',
	'zhaoyuanedu.cn',
	'mailseal.de',
	'tmail.ws',
	'mailpooch.com',
	'konican.com',
	'magim.be',
	'hvastudiesucces.nl',
	'nongnue.com',
	'inoutmail.eu',
	'drevo.si',
	'xzsok.com',
	'balaket.com',
	'onlyapp.net',
	'epb.ro',
	'foreskin.ml',
	'kino-100.ru',
	'senseless-entertainment.com',
	'mkpfilm.com',
	'gnctr-calgary.com',
	'housat.com',
	'spymail.com',
	'pecinan.org',
	'boofx.com',
	'efxs.ca',
	'octovie.com',
	'lifetotech.com',
	'pi.vu',
	'kasmail.com',
	'soon.it',
	'umail.net',
	'instant-mail.de',
	'tospage.com',
	'greendike.com',
	'd1yun.com',
	'kkmail.be',
	'bigwhoop.co.za',
	'shiftmail.com',
	'mxclip.com',
	'secmail.pw',
	'digital-message.com',
	'indomina.cf',
	'getnada.com',
	'mytempemail.com',
	'ushijima1129.cf',
	'gage.ga',
	'bussitussi.com',
	'qisoa.com',
	'remarkable.rocks',
	'super-auswahl.de',
	'moakt.co',
	'yahooproduct.net',
	'maildrop.ga',
	'polyfaust.net',
	'spamfree24.eu',
	'disposemail.com',
	'd3p.dk',
	'msrc.ml',
	'douchelounge.com',
	'mail-share.com',
	'36ru.com',
	'nguyenusedcars.com',
	'jakemsr.com',
	'chong-mail.com',
	'cl0ne.net',
	'reality-concept.club',
	'recipeforfailure.com',
	'ama-trans.de',
	'emailz.ml',
	'leeching.net',
	'abatido.com',
	'xrho.com',
	'euaqa.com',
	'autotwollow.com',
	'welikecookies.com',
	'ama-trade.de',
	'vkrr.store',
	'cyber-phone.eu',
	'n1nja.org',
	'analysiswe.us',
	'cheaphub.net',
	'wentcity.com',
	'mail666.ru',
	'kaengu.ru',
	'mailinator.gq',
	'camping-grill.info',
	'vkbt.ru',
	'shadap.org',
	'mail-now.top',
	'spamkill.info',
	'boxem.store',
	'mailjunk.cf',
	'12houremail.com',
	'lyft.live',
	'ippandansei.tk',
	'hungpackage.com',
	'degradedfun.net',
	'thankyou2010.com',
	'dotslashrage.com',
	'fightallspam.com',
	'rotaniliam.com',
	'thc.st',
	'e-marketstore.ru',
	'cam4you.cc',
	'cubene.com',
	'your5.ru',
	'dudmail.com',
	'edv.to',
	'55hosting.net',
	'ziragold.com',
	'xxlocanto.us',
	'pflege-schoene-haut.de',
	'instance-email.com',
	'tagmymedia.com',
	'miauj.com',
	'supermailer.jp',
	'altairwe.us',
	'autowb.com',
	'irssi.tv',
	'huangniu8.com',
	'hecat.es',
	'tempsky.com',
	'front14.org',
	'objectmail.com',
	'vmailing.info',
	'disaq.com',
	'stumpfwerk.com',
	'oovk.store',
	'ddcrew.com',
	'24hourmail.net',
	'brefmail.com',
	'getairmail.ml',
	'proxymail.eu',
	'inbax.tk',
	'faithkills.com',
	'getover.de',
	'discard.ga',
	'aelo.es',
	'dolphinnet.net',
	'fag.wf',
	'ver0.ml',
	'daemsteam.com',
	'nh3.ro',
	'meltmail.com',
	'maxturns.com',
	'emlhub.com',
	'mailita.tk',
	'qtum-ico.com',
	'kobrandly.com',
	'guerillamailblock.com',
	'solar-impact.pro',
	'psoxs.com',
	'4warding.com',
	'bestparadize.com',
	'toiea.com',
	'etranquil.org',
	'email-fake.com',
	'axiz.org',
	'putthisinyourspamdatabase.com',
	'jetable.com',
	'bbhost.us',
	'bidourlnks.com',
	'netricity.nl',
	'anappthat.com',
	'directmail24.net',
	'bbbbyyzz.info',
	'investore.co',
	'kakadua.net',
	'rkomo.com',
	'stop-my-spam.tk',
	'pimpedupmyspace.com',
	'lifebyfood.com',
	'4nextmail.com',
	'lovemeet.faith',
	'navalcadets.com',
	'kkoup.com',
	'yannmail.win',
	'neko2.net',
	'skrak.com',
	'mysamp.de',
	'allegrowe.us',
	'mailrock.biz',
	'1fsdfdsfsdf.tk',
	'yopmail.gq',
	'bofthew.com',
	'txcct.com',
	'helloricky.com',
	'unit7lahaina.com',
	'outlook.edu.pl',
	'boximail.com',
	'boxmail.lol',
	'emkei.gq',
	'wemel.top',
	'myspacepimpedup.com',
	'locomodev.net',
	'scrsot.com',
	'lak.pp.ua',
	'plhk.ru',
	'ksmtrck.tk',
	'emltmp.com',
	'10minut.xyz',
	'delivrmail.com',
	'domforfb27.tk',
	'vintomaper.com',
	'snece.com',
	'nobuma.com',
	'eripo.net',
	'zoemail.org',
	'tmpx.sa.com',
	'wwwnew.eu',
	'altitudewe.us',
	'now.im',
	'ambitiouswe.us',
	'ee2.pl',
	'webcontact-france.eu',
	'wegwerf-email.at',
	'trayna.com',
	'cobarekyo1.ml',
	'quicksend.ch',
	'p71ce1m.com',
	'aheadwe.us',
	'misterpinball.de',
	'spambox.info',
	'fansworldwide.de',
	'moimoi.re',
	'nice-4u.com',
	'veryrealemail.com',
	'weizixu.com',
	'venompen.com',
	'aphlog.com',
	'migmail.net',
	'slave-auctions.net',
	'tympe.net',
	'affiliate-nebenjob.info',
	'rackabzar.com',
	'vssms.com',
	'mailzilla.org',
	'krd.ag',
	'mwarner.org',
	'dodsi.com',
	'discard.cf',
	'sweetpotato.ml',
	'ovpn.to',
	'dodgemail.de',
	'norwegischlernen.info',
	'mailnuo.com',
	'kbox.li',
	'abilitywe.us',
	'payspun.com',
	'9q.ro',
	'hotsoup.be',
	'smellypotato.tk',
	'reliable-mail.com',
	'vtxmail.us',
	'dpptd.com',
	'kyal.pl',
	'bestlistbase.com',
	'dengekibunko.gq',
	'ver0.tk',
	'namewok.com',
	'newfilm24.ru',
	'pivo-bar.ru',
	'ohi.tw',
	'vddaz.com',
	'trbvm.com',
	'gamgling.com',
	'kruay.com',
	'blackgoldagency.ru',
	'owleyes.ch',
	'smartnator.com',
	'fuvk.ru',
	'mehr-bitcoin.de',
	'al-qaeda.us',
	'ip6.pp.ua',
	'inboxproxy.com',
	'procrackers.com',
	'rppkn.com',
	'nvhrw.com',
	'willselfdestruct.com',
	'1000rebates.stream',
	'go2vpn.net',
	'widget.gg',
	'mydemo.equipment',
	'photomark.net',
	'gdmail.top',
	'emailz.cf',
	'trash2011.com',
	'datazo.ca',
	'momentics.ru',
	'mail2rss.org',
	'primabananen.net',
	'vusra.com',
	'mailboxify.ru',
	'kiani.com',
	'barooko.com',
	'fuckme69.club',
	'gynzy.ro',
	'mailtv.tv',
	'geteit.com',
	'evyush.com',
	'herp.in',
	'amail.com',
	'bcaoo.com',
	'nnot.net',
	'mailme.lv',
	'buspad.org',
	'viralplays.com',
	'lru.me',
	'3l6.com',
	'guerillamail.biz',
	'facebook-email.cf',
	'hoanglong.tech',
	'proprietativalcea.ro',
	'aliaswe.us',
	'a45.in',
	'spam.org.es',
	'3trtretgfrfe.tk',
	'neghtlefi.com',
	'mailt.top',
	'fastyamaha.com',
	'z86.ru',
	'ee1.pl',
	'vercelli.gq',
	'karta-kykyruza.ru',
	'deyom.com',
	'pii.at',
	'metalunits.com',
	'tqosi.com',
	'vercelli.ml',
	'wg0.com',
	'mailinator1.com',
	'rklips.com',
	'duam.net',
	'maileme101.com',
	'anyalias.com',
	'wollan.info',
	'mechanicalresumes.com',
	'yanet.me',
	'streetwisemail.com',
	'fuwari.be',
	'uber-mail.com',
	'adios.email',
	'zhewei88.com',
	'visal168.ga',
	'liamcyrus.com',
	'furzauflunge.de',
	'5x25.com',
	'flyspam.com',
	'zehnminuten.de',
	'headstrong.de',
	'chinamkm.com',
	'etotvibor.ru',
	'maildrop.cf',
	'147.cl',
	'vkbb.ru',
	'domforfb23.tk',
	'qiott.com',
	'hidemail.us',
	'getairmail.com',
	'vixtricks.com',
	'amail4.me',
	'anotherdomaincyka.tk',
	'reimondo.com',
	'dingbone.com',
	'mailsac.com',
	'fasssd.store',
	'masonline.info',
	'remail.cf',
	'vesa.pw',
	'wegwerpmailadres.nl',
	'wekawa.com',
	'great-host.in',
	'4warding.org',
	'spambog.com',
	'spamfree.eu',
	'thnikka.com',
	'incq.com',
	'lain.ch',
	'mailers.edu.pl',
	'emailbbox.pro',
	'domforfb6.tk',
	'prin.be',
	'prtshr.com',
	'gynzy.gr',
	'in2reach.com',
	'glubex.com',
	'rtrtr.com',
	'spamthis.co.uk',
	'binkmail.com',
	'toss.pw',
	'notif.me',
	'knickerbockerban.de',
	'currentmail.com',
	'iencm.com',
	'rnailinator.com',
	'10minutmail.pl',
	'stop-my-spam.cf',
	'domforfb9.tk',
	'thrubay.com',
	'animesos.com',
	'hot-mail.tk',
	'b1of96u.com',
	'dropsin.net',
	'emailias.com',
	'basscode.org',
	'spamfighter.ml',
	'wecp.store',
	'emkei.tk',
	'onqin.com',
	'a-bc.net',
	'tempemail.biz',
	'desoz.com',
	'epbox.store',
	'seekapps.com',
	'munoubengoshi.gq',
	'spamavert.com',
	'sify.com',
	'tempmailer.de',
	'mailboxly.ru',
	'magspam.net',
	'myweblaw.com',
	'ml8.ca',
	'2anom.com',
	'klzlk.com',
	'plexolan.de',
	'fastacura.com',
	'dayrep.com',
	'5mail.cf',
	'rejectmail.com',
	'hot-mail.gq',
	'ginzi.net',
	'vorga.org',
	'apfelkorps.de',
	'myecho.es',
	'kulmeo.com',
	'sanstr.com',
	'psnator.com',
	'mycleaninbox.net',
	'4gfdsgfdgfd.tk',
	'jiooq.com',
	'mailincubator.com',
	'epostal.ru',
	'lyfestylecreditsolutions.com',
	'tvchd.com',
	'joseihorumon.info',
	'techemail.com',
	'goround.info',
	'spamfree24.org',
	'aoeuhtns.com',
	'mr24.co',
	'nbmbb.com',
	'affluentwe.us',
	'pokiemobile.com',
	'midlertidig.net',
	'muehlacker.tk',
	'popesodomy.com',
	'simaenaga.com',
	'myphantomemail.com',
	'uhhu.ru',
	'mailde.info',
	'cnamed.com',
	'672643.net',
	'1zhuan.com',
	'mliok.com',
	'azazazatashkent.tk',
	'8mail.ga',
	'ushijima1129.gq',
	'reallymymail.com',
	'soioa.com',
	'reftoken.net',
	'nullbox.info',
	'giveh2o.info',
	'tp-qa-mail.com',
	'one-mail.top',
	'gmxmail.win',
	'cuvox.de',
	'trash2010.com',
	'showslow.de',
	'mail0.ga',
	'oreidresume.com',
	'i2pmail.org',
	'oneoffmail.com',
	'mailme24.com',
	'fakemail.fr',
	'ny7.me',
	'firemailbox.club',
	'shippingterms.org',
	'shhmail.com',
	'visal168.gq',
	'tverya.com',
	'sika3.com',
	'liveradio.tk',
	'keipino.de',
	'moneypipe.net',
	'mutant.me',
	'reptilegenetics.com',
	'spamobox.com',
	'herpderp.nl',
	'giaiphapmuasam.com',
	'1chuan.com',
	'10minutemail.ml',
	'emailtech.info',
	'arur01.tk',
	'happydomik.ru',
	'qwickmail.com',
	'yogamaven.com',
	'mxfuel.com',
	'jellow.ml',
	'mjj.edu.ge',
	'mailms.com',
	'inggo.org',
	'meidecn.com',
	'webtrip.ch',
	'asdasd.nl',
	'isdaq.com',
	'boun.cr',
	'ginzi.es',
	'klassmaster.com',
	'spamlot.net',
	'domforfb8.tk',
	'sudern.de',
	'hangxomcuatoilatotoro.ml',
	'instmail.uk',
	'lawlita.com',
	'bestsoundeffects.com',
	'spruzme.com',
	'bearsarefuzzy.com',
	'ytnhy.com',
	'easy-trash-mail.com',
	'muell.monster',
	'psh.me',
	'coin-host.net',
	'buyordie.info',
	'nothingtoseehere.ca',
	'fextemp.com',
	'orgmbx.cc',
	'wimsg.com',
	'premium-mail.fr',
	'lookugly.com',
	'c1ph3r.xyz',
	'wegwerfemail.org',
	'mfunza.com',
	'uooos.com',
	'4k5.net',
	'gotmail.org',
	'gynzi.es',
	'airsi.de',
	'bgx.ro',
	'unmail.ru',
	'spamcowboy.org',
	'beribaza.ru',
	'tempmail.ws',
	'freefattymovies.com',
	'metaintern.net',
	'xcodes.net',
	'finews.biz',
	'kwilco.net',
	'xcoxc.com',
	'pizzajunk.com',
	'shitmail.org',
	'emailfake.ml',
	'nutpa.net',
	'selfdestructingmail.org',
	'mailjunk.gq',
	'lr7.us',
	'discard.gq',
	'loapq.com',
	'bunsenhoneydew.com',
	'teleworm.us',
	'timgiarevn.com',
	'alina-schiesser.ch',
	'greggamel.net',
	'1blackmoon.com',
	'hpc.tw',
	'newideasfornewpeople.info',
	'cloud-mail.top',
	'throya.com',
	'asu.su',
	'boxlet.store',
	'schrott-email.de',
	'cek.pm',
	'azmeil.tk',
	'in-ulm.de',
	'email-fake.tk',
	'g2xmail.top',
	'tmailinator.com',
	'20minutemail.com',
	'dim-coin.com',
	'revolvingdoorhoax.org',
	'brennendesreich.de',
	'carbtc.net',
	'tryalert.com',
	'sportrid.com',
	'rootfest.net',
	'emailz.gq',
	'cheatmail.de',
	'10mail.tk',
	'amiriindustries.com',
	'top101.de',
	'drdrb.net',
	'netzidiot.de',
	'disbox.org',
	'lillemap.net',
	'2120001.net',
	'appixie.com',
	'sofrge.com',
	'mobileninja.co.uk',
	'figjs.com',
	'6somok.ru',
	'txen.de',
	'mail7.io',
	'chong-mail.net',
	'merepost.com',
	'highbros.org',
	'alph.wtf',
	'mailnull.com',
	'mailmoth.com',
	'hotpop.com',
	'josse.ltd',
	'nomail.ga',
	'kadokawa.ml',
	'emailbin.net',
	'tempmail.it',
	'minimail.gq',
	'zebins.eu',
	'linuxmail.so',
	'clrmail.com',
	'fncp.ru',
	'spamcorptastic.com',
	'tmail.io',
	'harakirimail.com',
	'my-teddyy.ru',
	'knol-power.nl',
	'ufacturing.com',
	'scatmail.com',
	'cndps.com',
	'themostemail.com',
	'yamail.win',
	'dump-email.info',
	'cc.liamria',
	'zippymail.info',
	'disposeamail.com',
	'sry.li',
	'mhzayt.online',
	'epostal.store',
	'sociallymediocre.com',
	'3mail.ga',
	'opwebw.com',
	'guysmail.com',
	'bumpymail.com',
	'car101.pro',
	'matmayer.com',
	'agendawe.us',
	'evusd.com',
	'sperma.cf',
	'trashmail.me',
	'dyceroprojects.com',
	'shiphazmat.org',
	'curlhph.tk',
	'ppetw.com',
	'lazyinbox.com',
	'abakiss.com',
	'ebeschlussbuch.de',
	'pojok.ml',
	'ipsur.org',
	'guerrillamail.org',
	'spam.su',
	'vdig.com',
	'fake-email.pp.ua',
	'fake-mail.cf',
	'replyloop.com',
	'freehotmail.net',
	'powlearn.com',
	'valemail.net',
	'7mail.ml',
	'click-email.com',
	'mailinator.org',
	'6mail.cf',
	'evnft.com',
	'10minutemail.us',
	'arvato-community.de',
	'kvhrr.com',
	'smwg.info',
	'ryyr.ru',
	'yourlms.biz',
	'mailinator7.com',
	'nm7.cc',
	'linkedintuts2016.pw',
	'easytrashmail.com',
	'hostingmail.me',
	'10minutemail.co.za',
	'mailmate.com',
	'nationalgardeningclub.com',
	'jmail.ovh',
	'emaildbox.pro',
	'jobbikszimpatizans.hu',
	'testudine.com',
	'bootybay.de',
	'deadfake.ml',
	'ilovespam.com',
	'kellychibale-researchgroup-uct.com',
	'anonymail.dk',
	'vomoto.com',
	'mykickassideas.com',
	'jpco.org',
	'netviewer-france.com',
	'xy9ce.tk',
	'spambob.org',
	'kvhrw.com',
	'petrzilka.net',
	'9ox.net',
	'pinehill-seattle.org',
	'gsredcross.org',
	'aegiscorp.net',
	'aegia.net',
	'alchemywe.us',
	'trasz.com',
	'meltedbrownies.com',
	'wuzak.com',
	'eay.jp',
	'email-jetable.fr',
	'email.edu.pl',
	'tcwlm.com',
	'bio-muesli.info',
	'ourklips.com',
	'predatorrat.ml',
	'free-temp.net',
	'motique.de',
	'grr.la',
	'helpinghandtaxcenter.org',
	'punkass.com',
	'rudymail.ml',
	'ver0.cf',
	'rabitex.com',
	'myspaceinc.net',
	'shhuut.org',
	'bgtmail.com',
	'bst-72.com',
	'discard.email',
	'emeil.in',
	'mailinator2.com',
	'dasdasdascyka.tk',
	'guerillamail.com',
	'yarnpedia.ga',
	'razuz.com',
	'softpls.asia',
	'poutineyourface.com',
	'throam.com',
	'fixmail.tk',
	'fr.nf',
	'ronete.com',
	'briggsmarcus.com',
	'ternaklele.ga',
	'thrott.com',
	'posta.store',
	'mamulenok.ru',
	'yspend.com',
	'cuirushi.org',
	'mail-help.net',
	'tpwlb.com',
	'accordwe.us',
	'dred.ru',
	'pig.pp.ua',
	'xn--9kq967o.com',
	'exweme.com',
	'duck2.club',
	'zik.dj',
	'e3z.de',
	'ipoo.org',
	'lolfreak.net',
	'reconmail.com',
	'top1post.ru',
	'dumpandjunk.com',
	'mailfirst.icu',
	'mytrashmail.com',
	'skeefmail.com',
	'youpymail.com',
	'fragolina2.tk',
	'nekosan.uk',
	'sendapp.uk',
	'web-inc.net',
	'findemail.info',
	'cycinst.com',
	'coreclip.com',
	'lista.cc',
	'diwaq.com',
	'ethersports.org',
	'inboxclean.com',
	'chammy.info',
	'maidlow.info',
	'domforfb7.tk',
	'secured-link.net',
	'alivewe.us',
	'jellyrolls.com',
	'choco.la',
	'speedgaus.net',
	'acrylicwe.us',
	'mailinator0.com',
	'fasssd.ru',
	'rustydoor.com',
	'kutakbisajauhjauh.gq',
	'wenkuu.com',
	'xmail.com',
	'greggamel.com',
	'mailsiphon.com',
	'mailscrap.com',
	'gsrv.co.uk',
	'miucce.com',
	'inboxnow.store',
	'delayload.net',
	'ctos.ch',
	'tyldd.com',
	'yourdomain.com',
	'willhackforfood.biz',
	'goonby.com',
	'mirai.re',
	'dob.jp',
	'dab.ro',
	'seekjobs4u.com',
	'inkomail.com',
	'mohmal.in',
	'mailhex.com',
	'vkbb.store',
	'twkly.ml',
	'cars2.club',
	'gothere.biz',
	'gardenscape.ca',
	'air2token.com',
	'instantblingmail.info',
	'amplifiedwe.us',
	'emailresort.com',
	'mailosaur.net',
	'garbagemail.org',
	'hochsitze.com',
	'cdpa.cc',
	'ozyl.de',
	'kpay.be',
	'z0d.eu',
	'hartbot.de',
	'puglieisi.com',
	'kadokawa.cf',
	'wegwrfmail.org',
	'mailox.fun',
	'nevermail.de',
	'womp-wo.mp',
	'jmail.ro',
	'fastmazda.com',
	'0x207.info',
	'mailhazard.com',
	'rippb.com',
	'myspaceinc.com',
	'ginzy.co.uk',
	'0v.ro',
	'mailquack.com',
	'salmeow.tk',
	'0845.ru',
	'locantowsite.club',
	'meinspamschutz.de',
	'indosukses.press',
	'omtecha.com',
	'yui.it',
	'bot.nu',
	'vradportal.com',
	'bluedumpling.info',
	'disposable.cf',
	'gsxstring.ga',
	'fexbox.ru',
	'uploadnolimit.com',
	'pwrby.com',
	'vipmail.name',
	'bobmail.info',
	'cu.cc',
	'grish.de',
	'azuretechtalk.net',
	'emailfoxi.pro',
	'ssoia.com',
	'kamen-market.ru',
	'trashemails.de',
	'keinhirn.de',
	'pixdd.com',
	'okinawa.li',
	'allaroundwe.us',
	'disposable.site',
	'shopxda.com',
	'mailbox80.biz',
	'imail1.net',
	'mailinator.com',
	'aditus.info',
	'advocatewe.us',
	'laymro.com',
	'voxelcore.com',
	'bnote.com',
	'fallinhay.com',
	'ich-essen-fleisch.bio',
	'spam.ceo',
	'thex.ro',
	'csh.ro',
	'jobbrett.com',
	'shrib.com',
	'1webmail.info',
	'zxcvbnm.com',
	'thxmate.com',
	'you-spam.com',
	'getonemail.com',
	'cloudflare.gay',
	'garasikita.pw',
	'mail707.com',
	'1-tm.com',
	'pwpwa.com',
	'krypton.tk',
	'acceleratewe.us',
	'aiworldx.com',
	'gynzy.es',
	'mailmoat.com',
	'viditag.com',
	'dcemail.com',
	'shitaway.tk',
	'ontyne.biz',
	'thelightningmail.net',
	'internet-v-stavropole.ru',
	'incognitomail.net',
	'ac20mail.in',
	'toomail.biz',
	'avia-tonic.fr',
	'yeezus.ru',
	'byebyemail.com',
	'stromox.com',
	'emailgo.de',
	'wfgdfhj.tk',
	'remote.li',
	'g4hdrop.us',
	'pipemail.space',
	'projectcl.com',
	'lyricspad.net',
	'heros3.com',
	'emailz.ga',
	'binnary.com',
	'zomg.info',
	'fivemail.de',
	'hmamail.com',
	'astonut.tk',
	'sackboii.com',
	'qipmail.net',
	'unimark.org',
	'mofu.be',
	'mywrld.site',
	'spamwc.gq',
	'spaminator.de',
	'emailspam.tk',
	'ushijima1129.ga',
	'abacuswe.us',
	'trashmail.ws',
	'whaaaaaaaaaat.com',
	're-gister.com',
	'mailboxy.store',
	'thejoker5.com',
	'antireg.com',
	'storiqax.top',
	'inboxalias.com',
	'appinventor.nl',
	'onlineidea.info',
	'12storage.com',
	'secretemail.de',
	'internetoftags.com',
	'trbvo.com',
	'hornyalwary.top',
	'75hosting.org',
	'ryyr.store',
	'gotmail.net',
	'inboxdesign.me',
	'macromaid.com',
	'sexforswingers.com',
	'gamegregious.com',
	'starmail.net',
	'topinrock.cf',
	'powered.name',
	'achievementwe.us',
	'usbc.be',
	'pitaniezdorovie.ru',
	'swift10minutemail.com',
	'myde.ml',
	'lordsofts.com',
	'mycorneroftheinter.net',
	'spamfree24.com',
	'theroyalweb.club',
	'artman-conception.com',
	'alphaomegawe.us',
	'datum2.com',
	'mt2014.com',
	'candymail.de',
	'vixletdev.com',
	'hatespam.org',
	'myopang.com',
	'pamaweb.com',
	'xl.cx',
	'clipmail.eu',
	'sandcars.net',
	'c51vsgq.com',
	'cuoly.com',
	'emailage.cf',
	'smashmail.de',
	'tempalias.com',
	'fun64.com',
	'tempthe.net',
	'watchfull.net',
	'mail4trash.com',
	'tormail.org',
	'fanclub.pm',
	'spamherelots.com',
	'0wnd.org',
	'foxtrotter.info',
	'rbb.org',
	'emailigo.de',
	'temp-mail.org',
	'emailcu.icu',
	'faecesmail.me',
	'ohaaa.de',
	'samsclass.info',
	'123-m.com',
	'trend-maker.ru',
	'discos4.com',
	'sofortmail.de',
	'fastmitsubishi.com',
	'nwytg.com',
	'zsero.com',
	'top-shop-tovar.ru',
	'myfxspot.com',
	'mailinator2.net',
	'myinfoinc.com',
	'dispose.it',
	'watch-harry-potter.com',
	'silenceofthespam.com',
	'mailfall.com',
	'fleckens.hu',
	'riski.cf',
	'ls-server.ru',
	'us.to',
	'disign-concept.eu',
	'0wnd.net',
	'24hourmail.com',
	'mepost.pw',
	'bookthemmore.com',
	'odem.com',
	'unicodeworld.com',
	'nomail.cf',
	'rifkian.ga',
	'fyii.de',
	'genderfuck.net',
	'girlfriend.ru',
	'btc.email',
	'freundin.ru',
	'mailismagic.com',
	'co.cc',
	'spikio.com',
	'gishpuppy.com',
	'emailspam.ga',
	'usako.net',
	'enterto.com',
	'mailorc.com',
	'mailmetrash.com',
	'12hosting.net',
	'okclprojects.com',
	'webm4il.info',
	'inpwa.com',
	'gregorygamel.com',
	'vkr1.com',
	'orsbap.com',
	'moakt.cc',
	'5minutemail.net',
	'vidchart.com',
	'resgedvgfed.tk',
	'fullangle.org',
	'bukhariansiddur.com',
	'einrot.com',
	'tonaeto.com',
	'inboxclean.org',
	'tcwlx.com',
	'temporaryinbox.com',
	'ucche.us',
	'recyclemail.dk',
	'wuzup.net',
	'lroid.com',
	'temp-mail.ru',
	'edgex.ru',
	'bcooq.com',
	'macr2.com',
	'dash-pads.com',
	'mbx.cc',
	'droplar.com',
	'oovk.ru',
	'acclaimwe.us',
	'advantagewe.us',
	'dontsendmespam.de',
	'bazaaboom.com',
	'thelimestones.com',
	'kemptvillebaseball.com',
	'oonies-shoprus.ru',
	'netmails.com',
	'damai.webcam',
	'freeteenbums.com',
	'mail1web.org',
	'uu.gl',
	'gholar.com',
	'elearningjournal.org',
	'analyticswe.us',
	'royaldoodles.org',
	'm21.cc',
	'qiq.us',
	'emaillime.com',
	'20mail.it',
	'kaovo.com',
	'emeil.ir',
	'e4ward.com',
	'webhook.site',
	'temporaryforwarding.com',
	'slipry.net',
	'acrossgracealley.com',
	'instaddr.ch',
	'farrse.co.uk',
	'ushijima1129.tk',
	'topmail2.com',
	'alloywe.us',
	'9mail.cf',
	'deagot.com',
	'10minutemail.ga',
	'pptrvv.com',
	'gafy.net',
	'visal168.ml',
	'ht.cx',
	'medkabinet-uzi.ru',
	'glucosegrin.com',
	'infocom.zp.ua',
	'dshfjdafd.cloud',
	'anon-mail.de',
	'yourtube.ml',
	'spamstack.net',
	'adroh.com',
	'mytemp.email',
	'kyois.com',
	'shotmail.ru',
	'aaqwe.store',
	'btizet.pl',
	'freemails.ga',
	'jafps.com',
	'oolus.com',
	'electro.mn',
	'imperialcnk.com',
	'redfeathercrow.com',
	'mbox.re',
	'yourspamgoesto.space',
	'dz17.net',
	'katztube.com',
	'amail.club',
	'dndent.com',
	'makemetheking.com',
	'ploncy.com',
	'vevs.de',
	'americasbestwe.us',
	'asorent.com',
	'futuramind.com',
	'trixtrux1.ru',
	'ytpayy.com',
	'dildosfromspace.com',
	'mailpoof.com',
	'statdvr.com',
	'ptsculure.com',
	'bptfp.net',
	'cust.in',
	'thunderbolt.science',
	'tipsb.com',
	'adpugh.org',
	'mohmal.im',
	'disposablemails.com',
	'75hosting.com',
	'10minutemail.pro',
	'messagebeamer.de',
	'mailbucket.org',
	'ip6.li',
	'5ymail.com',
	'emailthe.net',
	'robertspcrepair.com',
	'qmrbe.com',
	'crusthost.com',
	'ether123.net',
	'nyasan.com',
	'oneoffemail.com',
	'your5.store',
	'gixenmixen.com',
	'doquier.tk',
	'gimpmail.com',
	'uyhip.com',
	'mailinator4.com',
	'fantasymail.de',
	'qc.to',
	'astroempires.info',
	'iheartspam.org',
	'nowmymail.com',
	'lellno.gq',
	'10minutenemail.de',
	'mailbiscuit.com',
	'totoan.info',
	'instantemailaddress.com',
	'20mail.in',
	'bepureme.com',
	'eth2btc.info',
	'fer-gabon.org',
	'zipo1.gq',
	'10minutemail.de',
	'aron.us',
	'sneakmail.de',
	'cutefrogs.xyz',
	'filbert4u.com',
	'spamfree24.de',
	'ourpreviewdomain.com',
	'bund.us',
	'kadokawa.ga',
	'esgeneri.com',
	'hat-geld.de',
	'nnoway.ru',
	'gosuslugi-spravka.ru',
	'loan101.pro',
	'mailfree.ga',
	'veryday.ch',
	'ixaks.com',
	'twocowmail.net',
	'gudanglowongan.com',
	'donemail.ru',
	'teleworm.com',
	'domforfb2.tk',
	'vercelli.ga',
	'meruado.uk',
	'dammexe.net',
	'dialogus.com',
	'evvgo.com',
	'muell.io',
	'lakelivingstonrealestate.com',
	'cutout.club',
	'social-mailer.tk',
	'dicopto.com',
	'mysugartime.ru',
	'foreskin.ga',
	'emlpro.com',
	'emailabox.pro',
	'ynmrealty.com',
	'oloh.store',
	'yapped.net',
	'ji5.de',
	'mailtemp.info',
	'email-fake.gq',
	'isukrainestillacountry.com',
	'2012-2016.ru',
	'webuser.in',
	'tinoza.org',
	'instrete.com',
	'konultant-jurist.ru',
	'inmynetwork.tk',
	'moburl.com',
	'emailna.co',
	'haida-edu.cn',
	'disposable-e.ml',
	'klassmaster.net',
	'aegde.com',
	'coieo.com',
	'klovenode.com',
	'istii.ro',
	'topranklist.de',
	'8mail.ml',
	'web-ideal.fr',
	'qasti.com',
	'bangban.uk',
	'kpooa.com',
	'gedmail.win',
	'davidkoh.net',
	'hola.org',
	'pride-worldwi.de',
	'l33r.eu',
	'wxnw.net',
	'mailimate.com',
	'wegwerfemail.de',
	'davidlcreative.com',
	'dodgit.org',
	'emstjzh.com',
	'mm.my',
	'thatim.info',
	'fux0ringduh.com',
	'okrent.us',
	'socialfurry.org',
	'despam.it',
	'thietbivanphong.asia',
	'adubiz.info',
	'go2usa.info',
	'trashymail.com',
	'10minut.com.pl',
	'grandmasmail.com',
	'magamail.com',
	'rainbowly.ml',
	'allseasonswe.us',
	'6hjgjhgkilkj.tk',
	'fuckxxme.top',
	'mailzilla.com',
	'wronghead.com',
	'sohai.ml',
	'bartdevos.be',
	'antispam24.de',
	'a7996.com',
	'ecallheandi.com',
	'mailshell.com',
	'eanok.com',
	'trashmail.io',
	'jet-renovation.fr',
	'fettometern.com',
	'prtnx.com',
	'grugrug.ru',
	'quickemail.info',
	'yahmail.top',
	'doanart.com',
	'gynzy.mobi',
	'yordanmail.cf',
	'fictionsite.com',
	'grandmamail.com',
	'trackden.com',
	'junkmail.ga',
	'adventurewe.us',
	'cuendita.com',
	'dispo.in',
	'ado888.biz',
	'der-kombi.de',
	'soyboy.observer',
	'vasteron.com',
	'goplaygame.ru',
	'crazespaces.pw',
	'sute.jp',
	'aaqwe.ru',
	'omnievents.org',
	'obobbo.com',
	'blackmarket.to',
	'korona-nedvizhimosti.ru',
	'msssg.com',
	'jeoce.com',
	'bsnow.net',
	'superyp.com',
	'myzx.com',
	'shipping-regulations.com',
	'fastsuzuki.com',
	'borged.com',
	'borged.org',
	'tutuapp.bid',
	'10mail.xyz',
	'vaasfc4.tk',
	'ikuromi.com',
	'greensloth.com',
	'liocbrco.com',
	'20email.eu',
	'a-germandu.de',
	'hazelnut4u.com',
	'litedrop.com',
	'syinxun.com',
	'discardmail.de',
	'ginzy.eu',
	'chilkat.com',
	'humaility.com',
	'wegwerf-email.de',
	'sanim.net',
	'kmail.live',
	'xcode.ro',
	'civx.org',
	'emailo.pro',
	'xww.ro',
	'myemailboxy.com',
	'rcasd.com',
	'twoweirdtricks.com',
	'gruene-no-thanks.xyz',
	'email-lab.com',
	'placebomail10.com',
	'mailinator.net',
	'siliwangi.ga',
	'ramenmail.de',
	'penoto.tk',
	'predatorrat.tk',
	'digitalmariachis.com',
	'spoofmail.de',
	'hairs24.ru',
	'heisei.be',
	'proxyparking.com',
	'shipfromto.com',
	'blondemorkin.com',
	'kara-turk.net',
	'mailboxify.store',
	'waterisgone.com',
	'7days-printing.com',
	'alivance.com',
	'trashmailer.com',
	'ultrada.ru',
	'kurzepost.de',
	'openavz.com',
	'gexik.com',
	'tempemail.co.za',
	'loadby.us',
	'filberts4u.com',
	'gynzy.sk',
	'cupbest.com',
	'ldop.com',
	'z-o-e-v-a.ru',
	'tech69.com',
	'fasttoyota.com',
	'xepa.ru',
	'4-n.us',
	'express.net.ua',
	'1-8.biz',
	'chapsmail.com',
	'temporarily.de',
	'dotmsg.com',
	'renydox.com',
	'exdonuts.com',
	'bigstring.com',
	'trashcanmail.com',
	'nogmailspam.info',
	'fr.cr',
	'spamsalad.in',
	'bbitq.com',
	'mtmdev.com',
	'hacccc.com',
	'pancakemail.com',
	'2fdgdfgdfgdf.tk',
	'photo-impact.eu',
	'spritzzone.de',
	'spr.io',
	'sweetxxx.de',
	'chogmail.com',
	'gowikitravel.com',
	'freebabysittercam.com',
	'sueshaw.com',
	'centermail.com',
	'mailinator6.com',
	'mailed.ro',
	'rancidhome.net',
	'extremail.ru',
	'txtadvertise.com',
	'idxue.com',
	'szerz.com',
	'dukedish.com',
	'postacin.com',
	'bunchofidiots.com',
	'zzz.com',
	'monachat.tk',
	'codivide.com',
	'mailto.plus',
	'gosarlar.com',
	'newbpotato.tk',
	'privatdemail.net',
	'oepia.com',
	'banit.club',
	'pfui.ru',
	'niseko.be',
	'visal007.tk',
	'rebates.stream',
	'kariplan.com',
	'spamfree24.net',
	'mailinator.co.uk',
	'imailt.com',
	'xcpy.com',
	'prazdnik-37.ru',
	'lazyinbox.us',
	'popcornfly.com',
	'imgof.com',
	'mailcat.biz',
	'accreditedwe.us',
	'wbml.net',
	'10minutemail.cf',
	'pizu.ru',
	'mihep.com',
	'rma.ec',
	'kon42.com',
	'pastebitch.com',
	'totalvista.com',
	'topofertasdehoy.com',
	'axon7zte.com',
	'spamday.com',
	'vipxm.net',
	'buzzcluby.com',
	'zcovz.ru',
	'film-blog.biz',
	'instaddr.win',
	'cross-law.ga',
	'rax.la',
	'irc.so',
	'guerrillamail.de',
	'spaml.com',
	'caseedu.tk',
	'zain.site',
	'0815.su',
	'mailbox92.biz',
	'anthony-junkmail.com',
	'tempinbox.com',
	'delikkt.de',
	'mail22.club',
	'chansd.com',
	'art-en-ligne.pro',
	'nokiamail.com',
	'predatorrat.ga',
	'satisfyme.club',
	'loh.pp.ua',
	'dodgit.com',
	'albionwe.us',
	'exitstageleft.net',
	'startkeys.com',
	'owlpic.com',
	'login-email.ml',
	'wickmail.net',
	'mail-card.net',
	'betr.co',
	'emailate.com',
	'cameraity.com',
	'ezstest.com',
	'zebins.com',
	'chibakenma.ml',
	'irabops.com',
	'gowikicampus.com',
	'sfolkar.com',
	'okzk.com',
	'ws.gy',
	'gbmail.top',
	'gowikinetwork.com',
	'vankin.de',
	'hot-mail.ga',
	'tizi.com',
	'nezid.com',
	'emailage.tk',
	'fr33mail.info',
	'mywarnernet.net',
	'huskion.net',
	'trashinbox.com',
	'get-mail.ml',
	'disign-revelation.com',
	'starpower.space',
	'1to1mail.org',
	'guerrillamail.com',
	'jajxz.com',
	'childsavetrust.org',
	'comwest.de',
	'klipschx12.com',
	'ezfill.com',
	'tmpmail.org',
	'netmails.net',
	'ebbob.com',
	'crapmail.org',
	'dacoolest.com',
	'noref.in',
	'getairmail.tk',
	'deinbox.com',
	'mierdamail.com',
	'30wave.com',
	'send22u.info',
	'globaltouron.com',
	'ofisher.net',
	'hawrong.com',
	'mailcker.com',
	'5oz.ru',
	'gowikicars.com',
	'elitevipatlantamodels.com',
	'gynzy.info',
	'nada.ltd',
	'trollproject.com',
	'zipcad.com',
	'mailboxy.fun',
	'spammotel.com',
	'sikux.com',
	'worldspace.link',
	'kaspop.com',
	'americanawe.us',
	'chosenx.com',
	'alloutwe.us',
	'figshot.com',
	'80665.com',
	'hasanmail.ml',
	'spambob.net',
	'arroisijewellery.com',
	'sylvannet.com',
	'sino.tw',
	'email60.com',
	'inkiny.com',
	'tkitc.de',
	'winemaven.info',
	'honor-8.com',
	'deadfake.ga',
	'spybox.de',
	'0815.ry',
	'alpha-web.net',
	'10minutenmail.xyz',
	'mailt.net',
	'stopspam.app',
	'tanlanav.com',
	'elki-mkzn.ru',
	'hwsye.net',
	'heathenhero.com',
	'bundes-li.ga',
	'bugfoo.com',
	'fuckingduh.com',
	'almondwe.us',
	'ze.tc',
	'voltaer.com',
	'bank-opros1.ru',
	'activitywe.us',
	'ggvk.store',
	'rustyload.com',
	'apkmd.com',
	'used-product.fr',
	'no-ux.com',
	'675hosting.net',
	'wanko.be',
	'le-tim.ru',
	'ozatvn.com',
	'amiri.net',
	'bulrushpress.com',
	'thanksnospam.info',
	'koshu.ru',
	'escapehatchapp.com',
	'haltospam.com',
	'tempmailo.com',
	'gorillaswithdirtyarmpits.com',
	'mox.pp.ua',
	'guerillamail.net',
	'wetrainbayarea.com',
	'siberpay.com',
	'deekayen.us',
	'oroki.de',
	'americaswe.us',
	'notrnailinator.com',
	'wins.com.br',
	'host1s.com',
	'safetymail.info',
	'slopsbox.com',
	'wegwerfmail.org',
	'tarzanmail.cf',
	'ficken.de',
	'tmpbox.net',
	'midcoastcustoms.com',
	'webemail.me',
	'cmail.club',
	'thespamfather.com',
	'syujob.accountants',
	'kamsg.com',
	'claimab.com',
	'youmail.ga',
	'polarkingxx.ml',
	'vctel.com',
	'guerillamail.de',
	'predatorrat.gq',
	'regbypass.com',
	'pofmagic.com',
	'freemeil.ga',
	'foxja.com',
	'domforfb5.tk',
	'gett.icu',
	'chaichuang.com',
	'youxiang.dev',
	'andreihusanu.ro',
	'llogin.ru',
	'1ce.us',
	'fadingemail.com',
	'nyrmusic.com',
	'spamcero.com',
	'boxformail.in',
	'z1p.biz',
	'fakeinbox.cf',
	'cszbl.com',
	'ass.pp.ua',
	'3d-painting.com',
	'coolimpool.org',
	'btcmod.com',
	'averdov.com',
	'tmpmailtor.com',
	'tempr.email',
	'mezimages.net',
	'tryzoe.com',
	'trash-mail.ml',
	'asu.mx',
	'p33.org',
	'midcoastsolutions.com',
	'cfo2go.ro',
	'predatorrat.cf',
	'p-banlis.ru',
	'afrobacon.com',
	'ampsylike.com',
	'realquickemail.com',
	'activatewe.us',
	'fuwamofu.com',
	'fakeinbox.ml',
	'watrf.com',
	'drivetagdev.com',
	'extracurricularsociety.com',
	'dontreg.com',
	'mucincanon.com',
	'sandelf.de',
	'wegwerf-email.net',
	'akmail.in',
	'devnullmail.com',
	'dealrek.com',
	'puji.pro',
	'visal168.cf',
	'suexamplesb.com',
	'linshiyou.com',
	'bugmenot.com',
	'daabox.com',
	'nomail.pw',
	'blogspam.ro',
	'ieh-mail.de',
	'disposable.ga',
	'xojxe.com',
	'10minmail.de',
	'youneedmore.info',
	'guerrillamail.info',
	'curryworld.de',
	'temp-mails.com',
	'ro.lt',
	'abcmail.email',
	'iuemail.men',
	'nnh.com',
	'zasod.com',
	'freemails.cf',
	'smapfree24.com',
	'anonymousness.com',
	'alienware13.com',
	'negated.com',
	'uemail99.com',
	'oranek.com',
	'abuser.eu',
	'mailslite.com',
	'jobs-to-be-done.net',
	'fir.hk',
	'nobulk.com',
	'emailure.net',
	'hackthatbit.ch',
	'ttszuo.xyz',
	'spaml.de',
	'tempmail.de',
	'pecinan.com',
	'cmail.org',
	'vremonte24-store.ru',
	'spamthisplease.com',
	'oborudovanieizturcii.ru',
	'hulapla.de',
	'widaryanto.info',
	'rdklcrv.xyz',
	'veo.kr',
	'mailhazard.us',
	'miaferrari.com',
	'tmpemails.com',
	'lambsauce.de',
	'hypenated-domain.com',
	'spambox.org',
	'oosln.com',
	'blondmail.com',
	'stinkefinger.net',
	'l6factors.com',
	'raketenmann.de',
	'storj99.top',
	'viewcastmedia.com',
	'lerbhe.com',
	'add3000.pp.ua',
	'eelmail.com',
	'minsmail.com',
	'zipsendtest.com',
	'freemail.ms',
	'cartelera.org',
	'dozvon-spb.ru',
	'105kg.ru',
	'nonspam.eu',
	'geldwaschmaschine.de',
	'mailpluss.com',
	'privmail.edu.pl',
	'mm5.se',
	'antispammail.de',
	'gav0.com',
	'domforfb18.tk',
	'borged.net',
	'maildax.me',
	'disposable.ml',
	'iaoss.com',
	'nomorespamemails.com',
	'fulvie.com',
	'mji.ro',
	'beluckygame.com',
	'mciek.com',
	'emailage.ga',
	'demen.ml',
	'magicbox.ro',
	'whiffles.org',
	'icznn.com',
	'2ether.net',
	'lgxscreen.com',
	'ldaho.biz',
	'20mail.eu',
	'is.af',
	'spamcowboy.com',
	'4mail.cf',
	'any.pink',
	'valhalladev.com',
	'xxhamsterxx.ga',
	'link2mail.net',
	'tafoi.gr',
	'quick-mail.cc',
	'tempmail.pp.ua',
	'agger.ro',
	'morsin.com',
	'nodezine.com',
	'zarabotokdoma11.ru',
	'mailzi.ru',
	'jetable.pp.ua',
	'chewydonut.com',
	'anonymbox.com',
	'maboard.com',
	'spam4.me',
	'yxdad.ru',
	'hostguru.top',
	'hidzz.com',
	'cutradition.com',
	'tuofs.com',
	'wolfsmail.tk',
	'junk1e.com',
	'belgianairways.com',
	'habitue.net',
	'einrot.de',
	'hxopi.ru',
	'ask-mail.com',
	'whatpaas.com',
	'10x9.com',
	'icantbelieveineedtoexplainthisshit.com',
	'foreskin.gq',
	'maileimer.de',
	'trash2009.com',
	'ericjohnson.ml',
	'wmail.club',
	'saynotospams.com',
	'4mail.ga',
	'illistnoise.com',
	'1st-forms.com',
	'freemeil.gq',
	'envy17.com',
	'mozej.com',
	'mailslapping.com',
	'emailproxsy.com',
	'siteposter.net',
	'etranquil.com',
	'freeinbox.email',
	'vipmail.pw',
	'realtyalerts.ca',
	'ggvk.ru',
	'spamwc.ga',
	'telecomix.pl',
	'kcrw.de',
	'myindohome.services',
	'kmhow.com',
	'10minutemails.in',
	'remail.ga',
	'opmmedia.ga',
	'domozmail.com',
	'himail.online',
	'tatsu.uk',
	'4tb.host',
	'lifetimefriends.info',
	'bccto.me',
	'glitch.sx',
	'luckymail.org',
	'tropicalbass.info',
	'selfdestructingmail.com',
	'disposableemailaddresses.com',
	'beaconmessenger.com',
	'thechildrensfocus.com',
	'free-email.cf',
	'nospam4.us',
	'mailproxsy.com',
	'sofia.re',
	'kook.ml',
	'ownsyou.de',
	'sast.ro',
	'yecp.ru',
	'vda.ro',
	'lhsdv.com',
	'nanonym.ch',
	'svk.jp',
	'budayationghoa.com',
	'tonne.to',
	'allaccesswe.us',
	'de-a.org',
	'bigprofessor.so',
	'ploae.com',
	'royandk.com',
	'yoo.ro',
	'cctoolz.com',
	'ieatspam.eu',
	'zehnminutenmail.de',
	'wudet.men',
	'jungkamushukum.com',
	'muell.xyz',
	'hahawrong.com',
	'badgerland.eu',
	'jaqis.com',
	'sinema.ml',
	'mynetstore.de',
	'nonspammer.de',
	'tmpeml.com',
	'businessbackend.com',
	'civikli.com',
	'spamfellas.com',
	'kisstwink.com',
	'centermail.net',
	'nincsmail.com',
	'email1.pro',
	'disposemymail.com',
	'emailsensei.com',
	'mymailoasis.com',
	'coinlink.club',
	'sendnow.win',
	'jnxjn.com',
	'lacto.info',
	'get2mail.fr',
	'techgroup.me',
	'emailtemporanea.net',
	'nospamfor.us',
	'fake-mail.ga',
	'verdejo.com',
	'raxtest.com',
	'vmani.com',
	'hi2.in',
	'allofthem.net',
	'chielo.com',
	'alisree.com',
	'brand-app.biz',
	'ahk.jp',
	'kindamail.com',
	'g3xmail.top',
	'privymail.de',
	'newmail.top',
	'rejo.technology',
	'kappala.info',
	'r4nd0m.de',
	'morriesworld.ml',
	'tourcc.com',
	'spambox.us',
	'lez.se',
	'achievewe.us',
	'e-tomarigi.com',
	'lsyx24.com',
	'edinburgh-airporthotels.com',
	'mzico.com',
	'sgatra.com',
	'plw.me',
	'get-mail.ga',
	'lukemail.info',
	'eoopy.com',
	'adult-work.info',
	'toon.ml',
	'kinda.email',
	'sidement.com',
	'femailtor.com',
	'myspamless.com',
	'locantofuck.top',
	'xxi2.com',
	'cigar-auctions.com',
	'theeyeoftruth.com',
	'bspamfree.org',
	'fake-mail.ml',
	'fakemailgenerator.com',
	'onetm-ml.com',
	'tastrg.com',
	'zhaoqian.ninja',
	'thunkinator.org',
	'wsym.de',
	'bij.pl',
	'deadaddress.com',
	'emailxfer.com',
	'disbox.net',
	'mailinater.com',
	'lolmail.biz',
	'flexvio.com',
	'1secmail.com',
	'140unichars.com',
	'byom.de',
	'fluidsoft.us',
	'moakt.com',
	'porjoton.com',
	'rumgel.com',
	'0815.ru',
	'ququb.com',
	'pe.hu',
	'thembones.com.au',
	'cosmorph.com',
	'itunesgiftcodegenerator.com',
	'amadeuswe.us',
	'ra3.us',
	'eveav.com',
	'mytempmail.com',
	'email.net',
	'girlsindetention.com',
	'mswork.ru',
	'mockmyid.com',
	'ero-tube.org',
	'bum.net',
	'sinnlos-mail.de',
	'californiafitnessdeals.com',
	'wegwerf-emails.de',
	'pjjkp.com',
	'fxnxs.com',
	'mailorg.org',
	'uacro.com',
	'tempmail.cn',
	'leetmail.co',
	'girlsundertheinfluence.com',
	'wbdev.tech',
	'fly-ts.de',
	'tryninja.io',
	'broadbandninja.com',
	'rabiot.reisen',
	'seqerc.com',
	'fakemail.io',
	'daymailonline.com',
	'talkinator.com',
	'midlertidig.com',
	'mcache.net',
	'tsderp.com',
	'cocovpn.com',
	'matchpol.net',
	'facebookmail.gq',
	'naah.ru',
	'rm2rf.com',
	'ayakamail.cf',
	'pavilionx2.com',
	'diolang.com',
	'victoriantwins.com',
	'blogos.net',
	'carsencyclopedia.com',
	'tempemail.net',
	'5gramos.com',
	'aligamel.com',
	'amicuswe.us',
	'block521.com',
	'kloap.com',
	'mail21.cc',
	'1pad.de',
	'yourewronghereswhy.com',
	'vjoid.store',
	'amplifywe.us',
	'1s.fr',
	'moonwake.com',
	'spamfree24.info',
	'webmail24.top',
	'heathenhammer.com',
	'jourrapide.com',
	'tualias.com',
	'funnycodesnippets.com',
	'safetypost.de',
	'steambot.net',
	'mailme.ir',
	'xost.us',
	'slapsfromlastnight.com',
	'berirabotay.ru',
	'spamtrap.ro',
	'imails.info',
	'adobeccepdm.com',
	'tempe-mail.com',
	'stexsy.com',
	'tosunkaya.com',
	'asdasd.ru',
	'wegwerfemail.info',
	'fasternet.biz',
	'defomail.com',
	'twinmail.de',
	'rentaen.com',
	'garrymccooey.com',
	'safaat.cf',
	'ahem.email',
	'wiemei.com',
	'spambooger.com',
	'mailtome.de',
	'emeraldwebmail.com',
	'14n.co.uk',
	'1usemail.com',
	'hamham.uk',
	'ephemail.net',
	'10minutemail.gq',
	'klick-tipp.us',
	'augmentationtechnology.com',
	'mailsucker.net',
	'mansiondev.com',
	'labetteraverouge.at',
	'internetkeno.com',
	'papierkorb.me',
	'kvhrs.com',
	'wegwrfmail.net',
	'whatifanalytics.com',
	'pookmail.com',
	'memsg.site',
	'bdmuzic.pw',
	'larland.com',
	'pursip.com',
	'nwytg.net',
	'visal168.tk',
	'cbty.ru',
	'pa9e.com',
	'gotmail.com',
	'nomail2me.com',
	'xperiae5.com',
	'gynzy.pl',
	'freedom4you.info',
	'mailed.in',
	'kuhrap.com',
	'friendlymail.co.uk',
	'trash-me.com',
	'incognitomail.org',
	'zhcne.com',
	'nicknassar.com',
	'xkx.me',
	'fakeinbox.info',
	'atnextmail.com',
	'wallm.com',
	'gustr.com',
	'crazymailing.com',
	'ieatspam.info',
	'emailwarden.com',
	'c01.kr',
	'teewars.org',
	'messwiththebestdielikethe.rest',
	'sdvft.com',
	'spamsandwich.com',
	'notsharingmy.info',
	'yomail.info',
	'wokcy.com',
	'10minutemail.nl',
	'pro5g.com',
	'fdfdsfds.com',
	'thoas.ru',
	'99experts.com',
	'goatmail.uk',
	'egzones.com',
	'intopwa.org',
	'kisoq.com',
	'wifimaple.com',
	'opentrash.com',
	'online.ms',
	'moeri.org',
	'boatmail.us',
	'copyhome.win',
	'superblohey.com',
	'fakedemail.com',
	'dodgeit.com',
	'nproxi.com',
	'emaildrop.io',
	'ihateyoualot.info',
	'divad.ga',
	'smapfree24.eu',
	'emailforyou.info',
	'tensi.org',
	'rmqkr.net',
	'darkharvestfilms.com',
	'spamslicer.com',
	'wegwerf-email-adressen.de',
	'fuckedupload.com',
	'tempmail.us',
	'midlertidig.org',
	'hotmailproduct.com',
	'vmpanda.com',
	'clowmail.com',
	'bauwerke-online.com',
	'100likers.com',
	'monadi.ml',
	'emailspam.ml',
	'undo.it',
	'mail1a.de',
	'wwjmp.com',
	'fitnesrezink.ru',
	'sjuaq.com',
	'superrito.com',
	'msxd.com',
	'sandwhichvideo.com',
	'paharpurmim.ga',
	'imul.info',
	'brasx.org',
	'wegwerfemailadresse.com',
	'storj99.com',
	'qsl.ro',
	'getfun.men',
	'kmail.li',
	'b2bx.net',
	'girlmail.win',
	'login-email.tk',
	'giantmail.de',
	'tyhe.ro',
	'spamwc.ml',
	'rapt.be',
	'gmial.com',
	'taglead.com',
	'smallker.tk',
	'jobposts.net',
	'thichanthit.com',
	'getmule.com',
	'web-mail.pp.ua',
	'20mm.eu',
	'uiu.us',
	'opayq.com',
	'last-chance.pro',
	'gally.jp',
	'oshietechan.link',
	'urhen.com',
	'emailinfive.com',
	'dhy.cc',
	'owube.com',
	'max-mail.org',
	'b2cmail.de',
	'emailcbox.pro',
	'happy2023year.com',
	'trungtamtoeic.com',
	'addictingtrailers.com',
	'gruz-m.ru',
	'mailjunk.ga',
	'squizzy.net',
	'yopmail.com',
	'acceptwe.us',
	'trashmail.gq',
	'btb-notes.com',
	'emailto.de',
	'yopmail.net',
	'xrap.de',
	'getmails.eu',
	'laoeq.com',
	'datarca.com',
	'obfusko.com',
	'krsw.tk',
	'fakeinbox.com',
	'tmmbt.net',
	'janproz.com',
	'molms.com',
	'mygeoweb.info',
	'spambog.ru',
	'sdvrecft.com',
	'mailinator.info',
	'stayhome.li',
	'powerencry.com',
	'mymaily.lol',
	'ver0.ga',
	'box-mail.ru',
	'moot.es',
	'hiddentragedy.com',
	'mailtrix.net',
	'vermutlich.net',
	'migmail.pl',
	'xxvk.store',
	'losemymail.com',
	'mypartyclip.de',
	'onekisspresave.com',
	'technoproxy.ru',
	'inbound.plus',
	'ibnuh.bz',
	'mail-tester.com',
	'auti.st',
	'discard.ml',
	'jopho.com',
	'dropjar.com',
	'damnthespam.com',
	'throwam.com',
	'testore.co',
	'acuitywe.us',
	'stathost.net',
	'empireanime.ga',
	'virtualemail.info',
	'fukaru.com',
	'mailna.in',
	'contbay.com',
	'wef.gr',
	'eatmea2z.club',
	'dsgvo.ru',
	'akugu.com',
	'allstarwe.us',
	'forward.cat',
	'maildu.de',
	'chumpstakingdumps.com',
	'uk.to',
	'forecastertests.com',
	'je-recycle.info',
	'swift-mail.net',
	'yabai-oppai.tk',
	'trashmail.org',
	'explodemail.com',
	'malahov.de',
	'lakqs.com',
	'amazon-aws.org',
	'tefl.ro',
	'nut.cc',
	'watchironman3onlinefreefullmovie.com',
	'dmarc.ro',
	'2odem.com',
	'oing.cf',
	'lukecarriere.com',
	'i6.cloudns.cc',
	'pratikmail.net',
	'capitalistdilemma.com',
	'gmxmail.top',
	'dropmail.me',
	'nocp.store',
	'dlemail.ru',
	'nbzmr.com',
	'nervtmich.net',
	'writeme.us',
	'nmail.cf',
	'muell.icu',
	'fermaxxi.ru',
	'drmail.in',
	'spambog.de',
	'wegwerfmail.info',
	'ujijima1129.gq',
	'mailtothis.com',
	'adsd.org',
	'usa.cc',
	'nada.email',
	'getsimpleemail.com',
	'belamail.org',
	'yxzx.net',
	'kekita.com',
	'vidwobox.com',
	'whatiaas.com',
	'nakedtruth.biz',
	'givememail.club',
	'fiifke.de',
	'poopiebutt.club',
	'xxxhi.cc',
	'1clck2.com',
	'temp-mail.de',
	'cmail.com',
	'mailbiz.biz',
	'get-mail.cf',
	'thraml.com',
	'sharklasers.com',
	'flowu.com',
	'byespm.com',
	'fastchrysler.com',
	'moonapps.org',
	'gynzy.lt',
	'at.hm',
	'emailfake.com',
	'eyepaste.com',
	'telvetto.com',
	'zymuying.com',
	'iffymedia.com',
	'mailinator9.com',
	'taukah.com',
	'greencafe24.com',
	'freecat.net',
	'pratikmail.com',
	'inboxnow.ru',
	'dnses.ro',
	'spamfighter.gq',
	'duk33.com',
	'zcovz.store',
	'emkei.ml',
	'wazabi.club',
	'hmail.us',
	'mailna.me',
	'ishop2k.com',
	'porsh.net',
	'inboxbear.com',
	'msgos.com',
	'vubby.com',
	'kpost.be',
	'taobudao.com',
	'huizk.com',
	'muellemail.com',
	'vkfu.store',
	'moy-elektrik.ru',
	'evopo.com',
	'one-ml.com',
	'securehost.com.es',
	'bitymails.us',
	'smtp99.com',
	'shut.ws',
	'youmailr.com',
	'guerrillamail.net',
	'mail-easy.fr',
	'sin.cl',
	'lee.mx',
	's33db0x.com',
	'seosnaps.com',
	'juyouxi.com',
	'fakeinbox.tk',
	'emailnax.com',
	'mailbox52.ga',
	'trickmail.net',
	'pqoia.com',
	'malayalamdtp.com',
	'bobgf.store',
	'ariaz.jetzt',
	'ldtp.com',
	'gowikibooks.com',
	'wikidocuslava.ru',
	'coza.ro',
	'songsign.com',
	'digitalsanctuary.com',
	'blogmyway.org',
	'all-cats.ru',
	'acornwe.us',
	'dandikmail.com',
	'geekforex.com',
	'muathegame.com',
	'interstats.org',
	'spamtroll.net',
	'gowikimusic.com',
	'wegwerfadresse.de',
	'smapfree24.info',
	'emailtemporanea.com',
	'emailsingularity.net',
	'autosouvenir39.ru',
	'mailnesia.com',
	'shitmail.de',
	'moza.pl',
	'tb-on-line.net',
	'ligsb.com',
	'maswae.world',
	'emailawb.pro',
	'emails.ga',
	'temporaryemail.us',
	'nezdiro.org',
	'emaildienst.de',
	'imstations.com',
	'doublemail.de',
	'anonmail.top',
	'uuf.me',
	'getairmail.gq',
	'xxolocanto.us',
	'nezumi.be',
	'mdhc.tk',
	'afarek.com',
	'smapfree24.org',
	'mailtemporaire.com',
	'login-email.ga',
	'funnymail.de',
	'laafd.com',
	'pewpewpewpew.pw',
	'qibl.at',
	'sexyalwasmi.top',
	'maskmy.id',
	'whatsaas.com',
	'disposableinbox.com',
	'stop-my-spam.pp.ua',
	'fizmail.com',
	'weg-werf-email.de',
	'zemzar.net',
	'matamuasu.ga',
	'evilcomputer.com',
	'emailvb.pro',
	'autorobotica.com',
	'hot-mail.cf',
	'esprity.com',
	'thirifara.com',
	'yecp.store',
	'manybrain.com',
	'kartvelo.com',
	'suremail.info',
	'chatich.com',
	'garliclife.com',
	'smartemailbox.co',
	'adoniswe.us',
	'gowikifilms.com',
	'uhe2.com',
	'cmail.net',
	'hiltonvr.com',
	'digitalesbusiness.info',
	'dataarca.com',
	'tempemail.com',
	'wegwerfemail.com',
	'my10minutemail.com',
	'amail1.com',
	'throwawaymail.pp.ua',
	'2wc.info',
	'caainpt.com',
	'box-mail.store',
	'dwse.edu.pl',
	'indieclad.com',
	'frapmail.com',
	'cbair.com',
	'hxopi.store',
	'secure-mail.biz',
	'mailtv.net',
	'daily-email.com',
	'hezll.com',
	'nthrl.com',
	'6paq.com',
	'fuirio.com',
	'sd3.in',
	'tmail9.com',
	'pakadebu.ga',
	'getairmail.ga',
	'theteastory.info',
	'wegwrfmail.de',
	'flyinggeek.net',
	'qbfree.us',
	'laxex.ru',
	'ip4.pp.ua',
	'wwvk.store',
	'netris.net',
	'lenovog4.com',
	'cyber-innovation.club',
	'killmail.com',
	'sabrestlouis.com',
	'onemail.host',
	'tmails.net',
	'thedirhq.info',
	'walala.org',
	'tilien.com',
	'mailjunk.tk',
	'regapts.com',
	'breakthru.com',
	'ycn.ro',
	'bcast.ws',
	'amplewe.us',
	'freemeil.ml',
	'jdz.ro',
	'saharanightstempe.com',
	'piki.si',
	'ez.lv',
	'triots.com',
	'trash-mail.de',
	'boxtemp.com.br',
	'cantozil.com',
	'sky-inbox.com',
	'forspam.net',
	'dsiay.com',
	'kazelink.ml',
	'tmail.com',
	'trgfu.com',
	'inoutmail.de',
	'sausen.com',
	'loin.in',
	'quipas.com',
	'freerubli.ru',
	'stop-my-spam.ga',
	'getnowtoday.cf',
	'thescrappermovie.com',
	'legalrc.loan',
	'kulturbetrieb.info',
	'conf.work',
	'8127ep.com',
	'zdenka.net',
	'yuoia.com',
	'abovewe.us',
	'akapost.com',
	'rabin.ca',
	'wierie.tk',
	'singlespride.com',
	'petloca.com',
	'hi5.si',
	'mark-compressoren.ru',
	'w3internet.co.uk',
	'casualdx.com',
	'azcomputerworks.com',
	'bestoption25.club',
	'dspwebservices.com',
	'oopi.org',
	'suckmyd.com',
	'frwdmail.com',
	'puppetmail.de',
	'adfskj.com',
	'gifto12.com',
	'put2.net',
	'30mail.ir',
	'inclusiveprogress.com',
	'ordinaryamerican.net',
	'mailinator8.com',
	'dhm.ro',
	'xents.com',
	'cylab.org',
	'yroid.com',
	'zainmax.net',
	'hotmai.com',
	'boxem.ru',
	'lags.us',
	'hushmail.cf',
	'viewcastmedia.org',
	'easynetwork.info',
	'blnkt.net',
	'pepbot.com',
	'banit.me',
	'yaqp.com',
	'compareshippingrates.org',
	'mailna.biz',
	'mailpick.biz',
	'quickinbox.com',
	'gimme-cooki.es',
	'mailbox.zip',
	'domforfb4.tk',
	'kismail.ru',
	'otmail.jp',
	'trillianpro.com',
	'42o.org',
	'nocp.ru',
	'hthlm.com',
	'maileater.com',
	'ji7.de',
	'spam-be-gone.com',
	'mailbidon.com',
	'pingir.com',
	'emailaoa.pro',
	'soombo.com',
	'emailgenerator.de',
	'lukop.dk',
	'dharmatel.net',
	'techblast.ch',
	'spindl-e.com',
	'6mail.ml',
	'dbunker.com',
	'30minutemail.com',
	'wralawfirm.com',
	'tittbit.in',
	'bio-muesli.net',
	'yodx.ro',
	'killmail.net',
	'stanfordujjain.com',
	'wegwerfmail.de',
	'one-time.email',
	'crunchcompass.com',
	'emailage.gq',
	'mystvpn.com',
	'advantimo.com',
	'runi.ca',
	'soodonims.com',
	'emz.net',
	'best-john-boats.com',
	'mypacks.net',
	'2chmail.net',
	'garizo.com',
	'mailtrash.net',
	'lastmail.co',
	'mailfa.tk',
	'codeandscotch.com',
	'qabq.com',
	'affinitywe.us',
	'vuiy.pw',
	'wuespdj.xyz',
	'kimsdisk.com',
	'haribu.com',
	'incognitomail.com',
	'qvy.me',
	'zxcv.com',
	'groupbuff.com',
	'safermail.info',
	'academiccommunity.com',
	'zhorachu.com',
	'rfc822.org',
	'jetable.org',
	'teml.net',
	'shieldemail.com',
	'daymail.life',
	'timkassouf.com',
	'vjuum.com',
	'wecp.ru',
	'akerd.com',
	'dsgvo.party',
	'utiket.us',
	'ckaazaza.tk',
	'solventtrap.wiki',
	'dev-null.ga',
	'royalmarket.life',
	'mailfs.com',
	'rhyta.com',
	'emailportal.info',
	'spam.care',
	'gs-arc.org',
	'urfunktion.se',
	'vrmtr.com',
	'footard.com',
	'svetims.com',
	'fukurou.ch',
	'sdvgeft.com',
	'mama3.org',
	'poehali-otdihat.ru',
	'sendfree.org',
	'dumpmail.de',
	'uma3.be',
	'mailinator.us',
	'moreorcs.com',
	'musiccode.me',
	'server.ms',
	'domforfb19.tk',
	'spamcon.org',
	'lr78.com',
	'xemaps.com',
	'o2stk.org',
	'alisongamel.com',
	'vkrr.ru',
	'manifestgenerator.com',
	'eposta.work',
	'foreskin.cf',
	'nepwk.com',
	'mail-hub.info',
	'027168.com',
	'nincsmail.hu',
	'fexbox.org',
	'honeys.be',
	'mac-24.com',
	'victime.ninja',
	'bspooky.com',
	'sanfinder.com',
	'startfu.com',
	'myinterserver.ml',
	'sofort-mail.de',
	'no-trash.ru',
	'badoop.com',
	'ichigo.me',
	'allemojikeyboard.com',
	'vaati.org',
	'mspeciosa.com',
	'big1.us',
	'esc.la',
	'xcompress.com',
	'ftp.sh',
	'quickmail.nl',
	'mailf5.com',
	'keepmymail.com',
	'fakeinformation.com',
	'email-temp.com',
	'dotman.de',
	'tempmail.eu',
	'99.com',
	'intopwa.com',
	'binka.me',
	'cavi.mx',
	'totallynotfake.net',
	'toddsbighug.com',
	'freesourcecodes.com',
	'niwl.net',
	'nfast.net',
	'blip.ch',
	'alliancewe.us',
	'risu.be',
	'next.ovh',
	'drowblock.com',
	'getairmail.cf',
	'justemail.ml',
	'awsoo.com',
	'xagloo.com',
	'svxr.org',
	'lackmail.ru',
	'emailtemporar.ro',
	'fatflap.com',
	'g14l71lb.com',
	'wee.my',
	'oalsp.com',
	's0ny.net',
	'analogwe.us',
	'solvemail.info',
	'slaskpost.se',
	'tmpmail.net',
	'zombie-hive.com',
	'yugasandrika.com',
	'ineec.net',
	'fundapk.com',
	'privacy.net',
	'saeoil.com',
	'vkcode.ru',
	'trgovinanaveliko.info',
	'wakingupesther.com',
	'wwvk.ru',
	'bareed.ws',
	'getonemail.net',
	'roborena.com',
	'spamcowboy.net',
	'mobilevpn.top',
	'irish2me.com',
	'roguemaster.dev',
	'nezzart.com',
	'lackmail.net',
	'megasend.org',
	'spamex.com',
	'promailt.com',
	'freeblackbootytube.com',
	'spamhole.com',
	'stop-my-spam.ml',
	'ccmail.uk',
	'temp-mail.pp.ua',
	'ignoremail.com',
	'nervmich.net',
	'mdz.email',
	'cnew.ir',
	'mebelnu.info',
	'fastnissan.com',
	'pisls.com',
	'7tags.com',
	'xylar.ru',
	'f4k.es',
	'pokemail.net',
	'onetm.jp',
	'kksm.be',
	'sendspamhere.com',
	'gmatch.org',
	'snapwet.com',
	'clandest.in',
	'gmal.com',
	'joelpet.com',
	'wlist.ro',
	'phone-elkey.ru',
	'mail.by',
	'turoid.com',
	'kzccv.com',
	'nypato.com',
	'dp76.com',
	'affordablewe.us',
	'koszmail.pl',
	'ggmal.ml',
	'hellodream.mobi',
	'thecloudindex.com',
	'teleg.eu',
	'mt2009.com',
	'ucupdong.ml',
	'yedi.org',
	'us.af',
	'rover.info',
	'teerest.com',
	'edupolska.edu.pl',
	'schmeissweg.tk',
	'risingsuntouch.com',
	'10minutemail.be',
	'fucknloveme.top',
	'symphonyresume.com',
	'intopwa.net',
	'ethersportz.info',
	'bheps.com',
	'pecinan.net',
	'cachedot.net',
	'randomail.net',
	'ryteto.me',
	'macromice.info',
	'nobugmail.com',
	'delayload.com',
	'adaptivewe.us',
	'fudgerub.com',
	'cellurl.com',
	'spymail.one',
	'emailtmp.com',
	'vztc.com',
	'4nmv.ru',
	'sharedmailbox.org',
	'divermail.com',
	'10minutesmail.fr',
	'h8s.org',
	'chong-mail.org',
	'whopy.com',
	'free-email.ga',
	'no-spam.ws',
	'vnedu.me',
	'esbano-ru.ru',
	'net1mail.com',
	'ji6.de',
	'ycare.de',
	'freeschoolgirlvids.com',
	'tanukis.org',
	'thismail.net',
	'tmpjr.me',
	'fosil.pro',
	'5mail.ga',
	'trash-mail.com',
	'cbty.store',
	'sogetthis.com',
	'diapaulpainting.com',
	'omail.pro',
	'vkcbt.store',
	'mybitti.de',
	'mailbox87.de',
	'findu.pl',
	'fliegender.fish',
	'spamfighter.ga',
	'mailhub.pro',
	'domforfb29.tk',
	'tradermail.info',
	'akgq701.com',
	'dengekibunko.ga',
	'beribase.ru',
	'dumpyemail.com',
	'freesistercam.com',
	'675hosting.com',
	'fast-email.info',
	'afterhourswe.us',
	'gufum.com',
	'247web.net',
	'vsimcard.com',
	'postbx.store',
	'1secmail.org',
	'cdfaq.com',
	'tokem.co',
	'droolingfanboy.de',
	'mrvpm.net',
	'aa5zy64.com',
	'bcb.ro',
	'0clickemail.com',
	'fackme.gq',
	'smarttalent.pw',
	'instantletter.net',
	'2prong.com',
	'ckiso.com',
	'cetpass.com',
	'emkei.cf',
	'tempomail.fr',
	'tempymail.com',
	'smellfear.com',
	'spamsphere.com',
	'mailonaut.com',
	'profast.top',
	'brandallday.net',
	'anypng.com',
	'trash-mail.cf',
	'fangoh.com',
	'trash-mail.tk',
	'11163.com',
	'esadverse.com',
	'advisorwe.us',
	'filzmail.com',
	'siftportal.ru',
	'esemay.com',
	'catgroup.uk',
	'wupics.com',
	'intersteller.com',
	'10mail.org',
	'maggotymeat.ga',
	'fddns.ml',
	'theaviors.com',
	'burnthespam.info',
	'domforfb1.tk',
	'apps.dj',
	'ctmailing.us',
	'gelitik.in',
	'sailmail.io',
	'sky-ts.de',
	'mannawo.com',
	'freedompop.us',
	'0w.ro',
	'mywrld.top',
	'bouncr.com',
	'inactivemachine.com',
	'airmailbox.website',
	'fast-mail.fr',
	'10minutesmail.com',
	'24hinbox.com',
	'ironiebehindert.de',
	'uorak.com',
	'email-fake.ml',
	'ephemeral.email',
	'zoemail.net',
	'kludgemush.com',
	'w-asertun.ru',
	'piaa.me',
	'mountainregionallibrary.net',
	'adiq.eu',
	'qodiq.com',
	'fakemailz.com',
	'walkmail.net',
	'xjoi.com',
	'tdtda.com',
	'bestchoiceusedcar.com',
	'ano-mail.net',
	'ver0.gq',
	'loaoa.com',
	'get1mail.com',
	'fakeinbox.ga',
	'mjukglass.nu',
	'tellos.xyz',
	'dispomail.eu',
	'odaymail.com',
	'spacebazzar.ru',
	'cashflow35.com',
	'acumenwe.us',
	'mail.wtf',
	'watchever.biz',
	'muellmail.com',
	'12minutemail.com',
	'irishspringrealty.com',
	'gbcmail.win',
	'icx.ro',
	'allinonewe.us',
	'wlsom.com',
	'xmailer.be',
	'muchomail.com',
	'mailmaxy.one',
	'emailondeck.com',
	'affilikingz.de',
	'anonbox.net',
	'neomailbox.com',
	'scay.net',
	'arduino.hk',
	'emailtemporario.com.br',
	'emailspam.cf',
	'cl-cl.org',
	'postonline.me',
	'crossmailjet.com',
	'tqoai.com',
	'goemailgo.com',
	'budaya-tionghoa.com',
	'iralborz.bid',
	'nolemail.ga',
	'jofuso.com',
	'iwi.net',
	'admiralwe.us',
	'spambox.me',
	'moreawesomethanyou.com',
	'418.dk',
	'0nelce.com',
	'ikbenspamvrij.nl',
	'paplease.com',
	'idx4.com',
	'protempmail.com',
	'digdig.org',
	'wegwerfmail.net',
	'galaxy.tv',
	'adeptwe.us',
	'bbitj.com',
	'nagi.be',
	'notmailinator.com',
	'sheryli.com',
	'10minutemail.net',
	'silvercoin.life',
	'derkombi.de',
	'nabuma.com',
	'ige.es',
	'visignal.com',
	'discardmail.com',
	'inboxed.pw',
	'naslazhdai.ru',
	'steam-area.ru',
	'oida.icu',
	'fanicle.com',
	'kagi.be',
	'pro-tag.org',
	'login-email.cf',
	'bladesmail.net',
	'myspaceinc.org',
	'meepsheep.eu',
	'dcctb.com',
	'statiix.com',
	'spicysoda.com',
	'kadokawa.gq',
	'datenschutz.ru',
	'deadfake.tk',
	'chitthi.in',
	'merry.pink',
	'slushmail.com',
	'alpinewe.us',
	'ashleyandrew.com',
	'yermail.net',
	'emailisvalid.com',
	'ambiancewe.us',
	'dea-21olympic.com',
	'optimaweb.me',
	'guerrillamailblock.com',
	'abyssmail.com',
	'disposable-email.ml',
	'lpfmgmtltd.com',
	'allgoodwe.us',
	'bitwhites.top',
	'zv68.com',
	'gmx1mail.top',
	'existiert.net',
	'rollindo.agency',
	'markmurfin.com',
	'agedmail.com',
	'sexical.com',
	'9mot.ru',
	'dropcake.de',
	'vickaentb.tk',
	'buffemail.com',
	'slothmail.net',
	'kingsq.ga',
	'ronnierage.net',
	'ultra.fyi',
	'chenbot.email',
	'topmail2.net',
	'spamtrail.com',
	'mailfreeonline.com',
	'hidebox.org',
	'gynzy.at',
	'bluewerks.com',
	'svip520.cn',
	'jsrsolutions.com',
	'hotprice.co',
	'deadchildren.org',
	'neotlozhniy-zaim.ru',
	'mailback.com',
	'dispostable.com',
	'receiveee.com',
	'wpdork.com',
	'attnetwork.com',
	'belljonestax.com',
	'otherinbox.com',
	'taphear.com',
	'wpg.im',
	'coolandwacky.us',
	'mijnhva.nl',
	'jwork.ru',
	'giacmosuaviet.info',
	'zcrcd.com',
	'qoika.com',
	'grn.cc',
	'icemail.club',
	'my-pomsies.ru',
	'quadrafit.com',
	'domforfb3.tk',
	'midcoastcustoms.net',
	'gomail.in',
	'6url.com',
	'immo-gerance.info',
	'facebook-email.ga',
	'pleasenoham.org',
	'privy-mail.com',
	'mailfree.gq',
	'boxlet.ru',
	'enayu.com',
	'karatraman.ml',
	'uggsrock.com',
	'opp24.com',
	'tranceversal.com',
	'emkei.ga',
	'kiois.com',
	'sidelka-mytischi.ru',
	'kostenlosemailadresse.de',
	'eintagsmail.de',
	'duskmail.com',
	'shmeriously.com',
	'schachrol.com',
	'agtx.net',
	'oerpub.org',
	'5july.org',
	'web2mailco.com',
	'sohus.cn',
	'shalar.net',
	'doiea.com',
	'bione.co',
	'0-mail.com',
	'mailblocks.com',
	'ruru.be',
	'voidbay.com',
	'patonce.com',
	'moruzza.com',
	'mailna.co',
	'aegiswe.us',
	'junkmail.gq',
	'yhg.biz',
	'zfymail.com',
	'wellsfargocomcardholders.com',
	'6mail.ga',
	'vpn.st',
	'appzily.com',
	'gynzy.eu',
	'clixser.com',
	'thebearshark.com',
	'propscore.com',
	'thrma.com',
	'rsvhr.com',
	'nwldx.com',
	'e-mail.org',
	'300book.info',
	'eoffice.top',
	'trap-mail.de',
	'gzb.ro',
	'throwawaymail.com',
	'trashdevil.de',
	'thereddoors.online',
	'regspaces.tk',
	'emailmiser.com',
	'tempmailer.com',
	'divismail.ru',
	'tonymanso.com',
	'tempmailer.net',
	'posdz.com',
	'tmpeml.info',
	'rowe-solutions.com',
	'obxpestcontrol.com',
	'spam.la',
	'ixx.io',
	'webscash.com',
	'ihazspam.ca',
	'indirect.ws',
	'logular.com',
	'ecolo-online.fr',
	'indomaed.pw',
	'awdrt.org',
	'mailjunk.ml',
	'kuku.lu',
	'3202.com',
	'purcell.email',
	'afia.pro',
	'epbox.ru',
	'chalupaurybnicku.cz',
	'dongqing365.com',
	'facebookmail.ml',
	'ye.vc',
	'slippery.email',
	'laxex.store',
	'spamwc.cf',
	'sendingspecialflyers.com',
	'mnode.me',
	'freeml.net',
	'gawab.com',
	'kosmetik-obatkuat.com',
	'uwork4.us',
	'shitmail.me',
	'dr69.site',
	'mailforspam.com',
	'guerillamail.org',
	'givmail.com',
	'larisia.com',
	'chithinh.com',
	'21cn.com',
	'naah.store',
	'tmail.link',
	'foreastate.com',
	'eqiluxspam.ga',
	'barryogorman.com',
	'x24.com',
	'yopmail.pp.ua',
	'temporarymailaddress.com',
	'mailboxy.ru',
	'vkbt.store',
	'hazelnuts4u.com',
	'oxopoha.com',
	'amilegit.com',
	'ansibleemail.com',
	'dev-null.ml',
	'ministry-of-silly-walks.de',
	'skyrt.de',
	'digitalmail.info',
	'xoxox.cc',
	'zaym-zaym.ru',
	'nospamthanks.info',
	'mfsa.ru',
	'dishcatfish.com',
	'ubismail.net',
	'nonze.ro',
	'zoetropes.org',
	'itfast.net',
	'disposableaddress.com',
	'sly.io',
	'dev-null.gq',
	'cross-law.gq',
	'mandraghen.cf',
	'xbaby69.top',
	'clonemoi.tk',
	'laste.ml',
	'daryxfox.net',
	'bugmenever.com',
	'axsup.net',
	'shchiba.uk',
	'iozak.com',
	'hopemail.biz',
	'foreskin.tk',
	'sonshi.cf',
	'popcornfarm7.com',
	'mailhz.me',
	'temptami.com',
	'emailspam.gq',
	'rcpt.at',
	'frappina.tk',
	'pizu.store',
	'trashemail.de',
	'prtz.eu',
	'vkpr.store',
	'analyticwe.us',
	'abusemail.de',
	'xagloo.co',
	'toprumours.com',
	'ginzi.be',
	'mailapp.top',
	'sinfiltro.cl',
	'qopow.com',
	'peterdethier.com',
	'anonymized.org',
	'esterace.com',
	'mailinator5.com',
	'5tb.in',
	'mailfast.pro',
	'softkey-office.ru',
	'themegreview.com',
	'antireg.ru',
	'mailguard.me',
	'tempomail.org',
	'email-fake.cf',
	'itsjiff.com',
	'qq.my',
	'sofimail.com',
	'4057.com',
	'wifioak.com',
	'jp-ml.com',
	'temp-link.net',
	'hostcalls.com',
	'10minutemailbox.com',
	'agilewe.us',
	'spamdecoy.net',
	'rteet.com',
	'xmaily.com',
	'xyzfree.net',
	'fastsubaru.com',
	'buyusdomain.com',
	'onlatedotcom.info',
	'top1mail.ru',
	'hot-mail.ml',
	'cocoro.uk',
	'flymail.tk',
	'allegiancewe.us',
	'jdmadventures.com',
	'48hr.email',
	'outlawspam.com',
	'fake-box.com',
	'fammix.com',
	'10minutemail.co.uk',
	'fncp.store',
	'15qm.com',
	'inbox.si',
	'luv2.us',
	'20email.it',
	'wikfee.com',
	'ntlhelp.net',
	'inboxed.im',
	'trash-mail.ga',
	'sejaa.lv',
	'nextstopvalhalla.com',
	'giratex.com',
	'smellrear.com',
	'get.pp.ua',
	'failbone.com',
	'trashmail.at',
	'arurgitu.gq',
	'60minutemail.com',
	'gregorygamel.net',
	'mail1.top',
	'guerillamail.info',
	'kelenson.com',
	'trash-mail.gq',
	'1secmail.net',
	'discard.tk',
	'naymedia.com',
	'daibond.info',
	'ezztt.com',
	'btcmail.pw',
	'mailshiv.com',
	'20minutemail.it',
	'clout.wiki',
	'coldemail.info',
	'penisgoes.in',
	'upozowac.info',
	'flemail.ru',
	'supergreatmail.com',
	'mailinator3.com',
	'189.email',
	'nawmin.info',
	'gehensiemirnichtaufdensack.de',
	'hidemail.de',
	'andthen.us',
	'sharkfaces.com',
	'snakemail.com',
	'ind.st',
	'ftpinc.ca',
	'mailnator.com',
	'lortemail.dk',
	'businesssuccessislifesuccess.com',
	'grassdev.com',
	'inoutmail.info',
	'academywe.us',
	'amberwe.us',
	'ubm.md',
	'mowline.com',
	'mail-hosting.co',
	'spammer.fail',
	'olypmall.ru',
	'cooh-2.site',
	'popgx.com',
	'tacomail.de',
	'trashmailgenerator.de',
	'adwaterandstir.com',
	'agorawe.us',
	'lkgn.se',
	'throwawayemailaddress.com',
	'soisz.com',
	'bonobo.email',
	'mp-j.ga',
	'mask03.ru',
	'zzi.us',
	'sizzlemctwizzle.com',
	'cbes.net',
	'50set.ru',
	'satukosong.com',
	'services391.com',
	'isosq.com',
	'figurescoin.com',
	'stuckmail.com',
	'iubridge.com',
	'letthemeatspam.com',
	'xvx.us',
	'zaktouni.fr',
	'labworld.org',
	'mail-owl.com',
	'lastmail.com',
	'payperex2.com',
	'outmail.win',
	'uguuchantele.com',
	'guerrillamail.biz',
	'psles.com',
	'gynzi.co.uk',
	'thecarinformation.com',
	'truthfinderlogin.com',
	'g1xmail.top',
	'mailtraps.com',
	'allthegoodnamesaretaken.org',
	'poofy.org',
	'marketlink.info',
	'choicemail1.com',
	'kopagas.com',
	'xylar.store',
	'fuwa.be',
	'mailmenot.io',
	'zipcatfish.com',
	'maildrop.gq',
	'one2mail.info',
	'trashdevil.com',
	'chickenkiller.com',
	'esiix.com',
	'telegmail.com',
	'wuzupmail.net',
	'mvrht.net',
	'fexpost.com',
	'yopmail.fr',
	'wuuvo.com',
	'offshore-proxies.net',
	'crastination.de',
	'spamail.de',
	'yyolf.net',
	'ethereum1.top',
	'secure-mail.cc',
	'kopaka.net',
	'trash-amil.com',
	'e-mail.com',
	'wegwerf-email-addressen.de',
	'raqid.com',
	'antispam.de',
	'tinyurl24.com',
	'affiliatedwe.us',
	'sim-simka.ru',
	'beddly.com',
	'xxvk.ru',
	'rupayamail.com',
	'mailbox82.biz',
	'anappfor.com',
	'tiapz.com',
	'trashmail.com',
	'ruu.kr',
	'tapchicuoihoi.com',
	'yoggm.com',
	'freeletter.me',
	'gowikigames.com',
	'hidemail.pro',
	'cnsds.de',
	'fornow.eu',
	'instantmail.fr',
	'uroid.com',
	'italy-mail.com',
	'courrieltemporaire.com',
	'cs.email',
	'maildrop.ml',
	'pixiil.com',
	'cream.pink',
	'noclickemail.com',
	'8mail.cf',
	'riddermark.de',
	'spammy.host',
	'ghosttexter.de',
	'sroff.com',
	'inboxkitten.com',
	'hazmatshipping.org',
	'amelabs.com',
	'xxqx3802.com',
	'zoaxe.com',
	'ausgefallen.info',
	'6ip.us',
	'greenhousemail.com',
	'nm123.com',
	'temp-mail.com',
	'gxemail.men',
	'allowed.org',
	'1mail.ml',
	'maildrop.cc',
	'ovomail.co',
	'tmail3.com',
	'golemico.com',
	'clickdeal.co',
	'mydefipet.live',
	'freelance-france.eu',
	'mailcatch.com',
	'10-minute-mail.com',
	'odnorazovoe.ru',
	'robot-mail.com',
	'deadspam.com',
	'nuts2trade.com',
	'mailuniverse.co.uk',
	'tokenmail.de',
	'recode.me',
	'parlimentpetitioner.tk',
	'noblepioneer.com',
	'simpleitsecurity.info',
	'via.tokyo.jp',
	'pooae.com',
	'just-email.com',
	'metadownload.org',
	'4warding.net',
	'mailchop.com',
	'squizzy.de',
	'hccmail.win',
	'appc.se',
	'vikingsonly.com',
	'lacedmail.com',
	'packiu.com',
	'locanto1.club',
	'shieldedmail.com',
	'emailage.ml',
	'coffeetimer24.com',
	'geschent.biz',
	'fuvk.store',
	'oohioo.com',
	'emailfreedom.ml',
	'10mail.com',
	'postbx.ru',
	'7mail.ga',
	'mailisia.com',
	'votiputox.org',
	'shut.name',
	'uu2.ovh',
	'foobarbot.net',
	'onewaymail.com',
	'absolutewe.us',
	'spamfighter.cf',
	'wegwerfemail.net',
	'daintly.com',
	'razemail.com',
	'gcmail.top',
	'buyusedlibrarybooks.org',
	'tempmail2.com',
	'mt2015.com',
	'hs.vc',
	'email-fake.ga',
	'yuurok.com',
	'spamify.com',
	'tiv.cc',
	'tempail.com',
	'ceed.se',
	'inoutmail.net',
	'vps30.com',
	'nowmymail.net',
	'kademen.com',
	'starlight-breaker.net',
	'jumonji.tk',
	'nekochan.fr',
	'nextmail.info',
	'imgv.de',
	'jetable.net',
	'turual.com',
	'spamhereplease.com',
	'bflcafe.com',
	'bobgf.ru',
	'mohmal.tech',
	'submic.com',
	'politikerclub.de',
	'hukkmu.tk',
	'stuffmail.de',
	'hidebusiness.xyz',
	'wibblesmith.com',
	'totesmail.com',
	'mailinatar.com',
	'etranquil.net',
	'mailtechx.com',
	'snakebutt.com',
	'monumentmail.com',
	'mymail-in.net',
	'lindenbaumjapan.com',
	'instaddr.uk',
	'supersave.net',
	'at0mik.org',
	'einmalmail.de',
	'facebook-email.ml',
	'netcom.ws',
	'hackersquad.tk',
	'tofeat.com',
	'spambob.com',
	'mentonit.net',
	'crankhole.com',
	'noicd.com',
	'm4ilweb.info',
	'sluteen.com',
	'ajaxapp.net',
	'wilemail.com',
	'zzrgg.com',
	'whyspam.me',
	'landmail.co',
	'jaga.email',
	'75hosting.net',
	'avls.pt',
	'nurfuerspam.de',
	'evanfox.info',
	'thediamants.org',
	'yougotgoated.com',
	'hmh.ro',
	'clearwatermail.info',
	'mailme.gq',
	'temporary-mail.net',
	'tagyourself.com',
	'analyticalwe.us',
	'youremail.cf',
	'ginzi.co.uk',
	'mail-filter.com',
	'yep.it',
	'abevw.com',
	'temporarioemail.com.br',
	'joetestalot.com',
	'mailbox.in.ua',
	'anit.ro',
	'vkcbt.ru',
	'yxdad.store',
	'10dk.email',
	'fbma.tk',
	'trash-mail.at',
	'adaptwe.us',
	'spamfighter.tk',
	'opendns.ro',
	'trashmail.net',
	'shortmail.net',
	'superstachel.de',
	'ubinert.com',
	'pagamenti.tk',
	'soodmail.com',
	'unids.com',
	'derluxuswagen.de',
	'tic.ec',
	'cringemonster.com',
	'helpjobs.ru',
	'mohmal.com',
	'10minutemail.com',
	'allurewe.us',
	'spamoff.de',
	'poplk.com',
	'royal.net',
	'veryday.info',
	'zumpul.com',
	'skygazerhub.com',
	'stop-my-spam.com',
	'allamericanwe.us',
	'vipepe.com',
	'mainerfolg.info',
	'mailtemporaire.fr',
	'mfsa.info',
	'dealja.com',
	'tlpn.org',
	'vercelli.cf',
	'privy-mail.de',
	'beefmilk.com',
	'wolfmail.ml',
	'zepp.dk',
	'j-p.us',
	'lovemeleaveme.com',
	'wetrainbayarea.org',
	'vps911.net',
	'cane.pw',
	'99cows.com',
	'dengekibunko.ml',
	'inboxstore.me',
	'gowikitv.com',
	'accentwe.us',
	'lilo.me',
	'dev-null.cf',
	'burstmail.info',
	'mvrht.com',
	'rtskiya.xyz',
	'12minutemail.net',
	'o7i.net',
	'eeedv.de',
	'675hosting.org',
	'itcompu.com',
	'emailsy.info',
	'pratikmail.org',
	'forgetmail.com',
	'flurred.com',
	'junk.to',
	'walkmail.ru',
	'soodomail.com',
	'ahmedkhlef.com',
	'superplatyna.com',
	'igg.biz',
	'suioe.com',
	'tupmail.com',
	'bbitf.com',
	'skrx.tk',
	'icx.in',
	'migumail.com',
	'mail72.com',
	'nubescontrol.com',
	'vinernet.com',
	'haydoo.com',
	'purelogistics.org',
	'meantinc.com',
	'web-contact.info',
	'spamgoes.in',
	'zoemail.com',
	'hotmial.com',
	'turuma.com',
	'dawin.com',
	'lamasticots.com',
	'holl.ga',
	'xemne.com',
	'classesmail.com',
	'ezehe.com',
	'mrvpt.com',
	'tempmail.co',
	'tmpnator.live',
	'mail-temporaire.com',
	'mailin8r.com',
	'tempinbox.co.uk',
	'deadfake.cf',
	'veanlo.com',
	'hoanggiaanh.com',
	'stylist-volos.ru',
	'eposta.buzz',
	'donebyngle.com',
	'lilspam.com',
	'mail114.net',
	'mailpress.gq',
	'gamail.top',
	'mail-temporaire.fr',
	'na-cat.com',
	'suburbanthug.com',
	'norseforce.com',
	'fivermail.com',
	'cnmsg.net',
	'bobmurchison.com',
	'estate-invest.fr',
	'allprowe.us',
	'snapmail.cc',
	'gregorsky.zone',
	'pp7rvv.com',
	'fastkawasaki.com',
	'fastchevy.com',
	'pachilly.com',
	'olimp-case.ru',
	'safersignup.de',
	'tapi.re',
	'mailboxly.store',
	'rosebearmylove.ru',
	'alfaceti.com',
	'trashmail.de',
	'randomail.io',
	'aeonpsi.com',
	'0box.eu',
	'maildx.com',
	'geronra.com',
	'geew.ru',
	'inbox2.info',
	'puttanamaiala.tk',
	'wh4f.org',
	'trbvn.com',
	'eml.pp.ua',
	'chacuo.net',
	'kadokawa.tk',
	'kitnastar.com',
	'spamthis.network',
	'tempmaildemo.com',
	'syosetu.gq',
	'talmetry.com',
	'sinorto.com',
	'schafmail.de'
]);
